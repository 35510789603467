<template>
  <div class="common-layout">
    <el-container>
      <el-header>Header</el-header>
      <el-container>
        <el-aside width="200px">Aside</el-aside>
        <el-main>
          <!-- 使用 transition-group 来实现过渡动画 -->
          <el-timeline style="max-width: 600px">
            <transition-group name="fade-up" tag="div">
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="activity.timestamp"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                :hollow="activity.hollow"
                :timestamp="activity.timestamp"
                :class="['timeline-item', { 'show': visibleItems.has(index) }]"
              >
                <!-- 根据缩放比例控制 el-card 是否显示 -->
                <el-card v-if="isCardVisible">
                  <h4>Update Github template</h4>
                  <p>Tom committed {{ activity.timestamp }}</p>
                </el-card>
                <div v-else>
                  <!-- 显示缩放时不显示 card 时的内容 -->
                  {{ activity.content }}
                </div>
              </el-timeline-item>
            </transition-group>
          </el-timeline>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { debounce } from 'lodash'

const activities = ref([
  {
    content: 'Custom icon',
    timestamp: '2018-04-12 20:46',
    size: 'large',
    type: 'primary',
  },
  {
    content: 'Custom color',
    timestamp: '2018-04-03 20:46',
    color: '#0bbd87',
  },
  {
    content: 'Custom size',
    timestamp: '2018-04-03 20:46',
    size: 'large',
  },
  {
    content: 'Custom hollow',
    timestamp: '2018-04-03 20:46',
    type: 'primary',
    hollow: true,
  },
  {
    content: 'Default node',
    timestamp: '2018-04-03 20:46',
  },
])

// 控制 el-card 显示与隐藏
const isCardVisible = ref(true)

// 控制 timeline 项目是否可见
const visibleItems = ref(new Set()) // 用一个 Set 存储显示的项

// 根据缩放比例和窗口的变化判断显示哪些项目
// const isItemVisible = (index) => visibleItems.value.has(index)

// 监听窗口缩放，更新 el-card 显示状态
const handleResize = debounce(() => {
  const scale = window.devicePixelRatio
  isCardVisible.value = scale > 1.2
  // 重置并重新触发动画
  visibleItems.value.clear()
  handleVisibilityChange()
}, 200) // 防抖200毫秒

// 控制每个 timeline 项目的动画显示
const handleVisibilityChange = () => {
  activities.value.forEach((_, index) => {
    setTimeout(() => {
      visibleItems.value.add(index)
    }, index * 150) // 稍微加快动画间隔
  })
}

// Filtered activities based on visibility
// const filteredActivities = computed(() => activities.value)

// 在组件挂载时开始监听
onMounted(() => {
  window.addEventListener('resize', handleResize) // 监听窗口缩放事件
  handleResize() // 初始化时判断一次当前缩放比例
  handleVisibilityChange() // 初始化时设置可见项
})

// 在组件卸载时清除事件监听
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>




<style scoped>
.common-layout {
  height: 100vh;
}

.el-container {
  height: 100%;
}

.el-main {
  padding: 20px;
  overflow-y: auto;
}

.el-header {
  background-color: #74b9ff;
  color: white;
  padding: 15px;
  font-size: 20px;
  text-align: center;
}

.el-aside {
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.el-timeline {
  max-width: 800px;
  margin: 0 auto;
}

.timeline-item {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease-out;
}

.scaled-content {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease-out;
  padding: 8px;
  border-radius: 4px;
  background-color: #f5f7fa;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}

.timeline-item.show {
  opacity: 1;
  transform: translateY(0);
}

.el-timeline-item.visible {
  opacity: 1;
  transform: translateY(0);
}

/* 过渡动画 */
.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.6s, transform 0.6s;
}

.fade-up-enter-from,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>





